/* ==================================== Global RMIT Colour Definitions ==================================== */
:root {
    --rmit-blue: #000054;
    --rmit-blue-hover: #0c0c64;
    --rmit-red: #E61E2A;
    --rmit-grey: #E3E5E0;
}



/* ======================================= Custom Styling rmit.css ======================================== */
.color-rmit-blue {
    color: var(--rmit-blue) !important;
}

.color-rmit-red {
    color: var(--rmit-red) !important;
}

.ui.inverted.menu {
    background: var(--rmit-blue) !important;
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .item {
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .item:hover {
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .active.item {
    background: var(--rmit-red) !important;
    color: var(--rmit-grey) !important;
}

.ui.inverted.menu .active.item:hover {
    color: var(--rmit-grey) !important;
}

.ui.grey.header {
    color: var(--rmit-blue) !important;
}

.ui.blue.header {
    color: var(--rmit-blue) !important;
}

.ui.basic.blue.button, .ui.basic.blue.buttons .button {
    box-shadow: inset 0 0 0 1px var(--rmit-blue) !important;
    color: var(--rmit-blue) !important;
}

.ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover {
    box-shadow: inset 0 0 0 1px var(--rmit-red) !important;
    color: var(--rmit-red) !important;
}

.ui.basic.primary.button, .ui.basic.primary.buttons .button {
    box-shadow: inset 0 0 0 1px var(--rmit-blue) !important;
    color: var(--rmit-blue) !important;
}

.ui.blue.button, .ui.blue.buttons .button {
    background-color: var(--rmit-blue);
}

.ui.blue.button:hover, .ui.blue.buttons .button:hover {
    background-color: var(--rmit-red) !important;
}

.ui.blue.button:focus, .ui.blue.buttons .button:focus {
    background-color: var(--rmit-blue);
}

.ui.blue.active.button, .ui.blue.button .active.button:active, .ui.blue.buttons .active.button, .ui.blue.buttons .active.button:active {
    background-color: var(--rmit-blue);
}

.ui.teal.button:focus, .ui.teal.buttons .button:focus {
    background-color: var(--rmit-blue);
}

i.blue.icon {
    color: var(--rmit-blue) !important;
}

.ui.ordered.steps .step.active::before, .ui.steps .active.step .icon {
    color: var(--rmit-blue);
}

.ui.breadcrumb a {
    color: var(--rmit-blue);
}

.ui.breadcrumb a:hover {
    color: var(--rmit-blue-hover);
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--rmit-blue);
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: var(--rmit-blue-hover);
}

.ui.header {
    color: var(--rmit-blue) !important;
}

.ui.menu {
    font-family: Museo, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.ui.card .meta, .ui.cards > .card .meta {
    color: var(--rmit-blue) !important;
}

.ui.steps .step.active .title {
    color: var(--rmit-blue) !important;
}

.color-grey {
    color: var(--rmit-blue) !important;
}

.ui.blue.ribbon.label {
    background-color: #40407f;
    border-color:#40407f !important;
}

.ui.blue.label {
    background-color: var(--rmit-blue) !important;
}

.ui.primary.button:focus {
    background-color: var(--rmit-blue);
}

.ui.stacked.segment::after, .ui.stacked.segment::before, .ui.stacked.segments::after, .ui.stacked.segments::before { /* Hide login box double line */
    visibility: hidden;
}

.ui.list .list > a.item, .ui.list > a.item {
	color: var(--rmit-blue);
}

.ui.list .list > a.item:hover, .ui.list > a.item:hover {
	color: var(--rmit-red);
}